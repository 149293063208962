
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/widget/store/[id]",
      function () {
        return require("private-next-pages/widget/store/[id]/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/widget/store/[id]"])
      });
    }
  
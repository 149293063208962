import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import { Button } from "components/form/Button";
import { useState } from "react";
import { useUserCarts } from "components/jotai/cartAtom";
import JaneRefModal from "../modals/JaneRefModal";
import useDiscounts from "components/hooks/useDiscounts";
import { checkoutCartAtom } from "components/jotai/checkoutCartAtom";
import { useAtom } from "jotai";
import { useRouter } from "next/router";

export function StoreOrderButton({ cart, store }) {
	const router = useRouter();
	const { widgetOrderModal, setWidgetOrderModal, getTotalPrice } =
		useUserCarts();
	const [checkoutCart, setCheckoutCart] = useAtom(checkoutCartAtom);
	const [loading, setLoading] = useState(false);
	const [openJaneRefModal, setOpenJaneRefModal] = useState(false);
	const isWidget = router.pathname.startsWith("/widget");
	const { fetchDiscounts } = useDiscounts(store.id);

	if (!store?.id) {
		return null;
	}

	const onClickConfirmOrder = async () => {
		if (isWidget) {
			setOpenJaneRefModal(true);
			setWidgetOrderModal(false);
		} else {
			setLoading(true);
			await fetchDiscounts();
			const newTotalPrice = getTotalPrice(store.id);
			const newTotalItems = cart.products.reduce(
				(acc, product) => acc + product.count,
				0,
			);
			let updatedCart = {
				...cart,
				totalPrice: newTotalPrice,
				totalItems: newTotalItems,
			};
			if (cart?.discounts?.length) {
				const originalPrice = cart.discounts[0].original_cart_price;
				const priceAfterDiscount =
					cart.discounts[cart.discounts.length - 1].total_new_price;
				const discountAmount = originalPrice - priceAfterDiscount;
				updatedCart = {
					...updatedCart,
					discountAmount,
				};
			}
			setCheckoutCart(updatedCart);
			setWidgetOrderModal(true);
			setLoading(false);
		}
	};

	return (
		<>
			<JaneRefModal
				cart={cart}
				store={store}
				openJaneRefModal={openJaneRefModal}
				setOpenJaneRefModal={setOpenJaneRefModal}
			/>

			<Button primary onClick={onClickConfirmOrder} disabled={loading}>
				<span className="flex items-center gap-1 text-sm py-0.5">
					{loading ? "בודק מלאי" : "השלמת הזמנה"}
					<ArrowSmLeftIcon className="w-4 h-4" />
				</span>
			</Button>
		</>
	);
}

import { AuthContext } from "components/contexts/AuthContext";
import { useContext, useEffect, useRef, useState } from "react";
import { useHttp } from "./useHttp";
import useDownload, { FILE_NAMES } from "./useDownload";
import { userDocTypes } from "util/types";

const useSignature = () => {
	const { claims } = useContext(AuthContext);
	const { $upload } = useHttp();

	const { getFileUrls } = useDownload();

	const signatureRef = useRef(null);

	const [signatureModalOpen, setSignatureModalOpen] = useState(false);
	const [signatureUrl, setSignatureUrl] = useState(null);
	const [previewUrl, setPreviewUrl] = useState(null);
	const [isSignatureOverriten, setIsSignatureOverriten] = useState(false);
	const [loading, setLoading] = useState(false);

	const setDefaultSignature = async () => {
		const urls = await getFileUrls(FILE_NAMES.SIGNATURE);
		setSignatureUrl(urls.url);
		setPreviewUrl(urls.presigned_url);
		setIsSignatureOverriten(false);
	};

	const clear = () => {
		if (signatureRef.current) {
			signatureRef.current.clear();
		}
		setSignatureUrl(null);
		setPreviewUrl(null);
		setIsSignatureOverriten(false);
	};

	const handleEnd = () => {
		if (signatureRef.current && !signatureRef.current.isEmpty()) {
			setIsSignatureOverriten(true);
		} else {
			setIsSignatureOverriten(false);
			setSignatureUrl(null);
			setPreviewUrl(null);
		}
	};

	const submitSignature = async () => {
		if (signatureUrl) {
			setSignatureModalOpen(false);
		} else {
			setLoading(true);
			const dataUrl = signatureRef.current?.toDataURL("image/png", "base64");
			const response = await fetch(dataUrl);
			const blob = await response.blob();
			const file = new File([blob], "signature.png", { type: "image/png" });
			try {
				const res = await $upload(
					file,
					"documents",
					userDocTypes.user_signature,
				);
				if (res.ok) {
					setSignatureUrl(res.data.url);
					setPreviewUrl(res.data.presigned_url);
					setSignatureModalOpen(false);
				} else {
					throw res.error.message;
				}
			} catch (error) {
				console.error("Error uploading signature", error);
			} finally {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (claims && claims.has_signature_url && signatureModalOpen) {
			setDefaultSignature();
		}
	}, [claims?.has_signature_url, signatureModalOpen]);

	return {
		isSignatureOverriten,
		signatureRef,
		signatureModalOpen,
		setSignatureModalOpen,
		signatureUrl,
		setSignatureUrl,
		previewUrl,
		clear,
		submitSignature,
		loading,
		handleEnd,
	};
};
export default useSignature;

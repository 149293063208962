import { CacheContext } from "components/contexts/CacheContext";
import {
	useContext,
	useMemo,
	useLayoutEffect,
	useState,
	useEffect,
} from "react";
import { getProductFilters } from "util/commonFilters";
import { ServerTable } from "./server-table/ServerTable";
import { ProductsTableHiddenRow } from "components/products/ProductsTableHiddenRow";
import { ProductsTableColumns } from "components/products/ProductsTableColumns";
import { STORE_SORTBY_OPTIONS, toastMessage } from "util/common";
import { SingleStoreHeader } from "components/shop/SingleStoreHeader";
import { GiftIcon as GiftIconOutline } from "@heroicons/react/outline";
import StoreSortBy from "components/stores/StoreSortBy";
import QuickFilters from "./QuickFilters";
import { useUserCarts } from "components/jotai/cartAtom";
import { useStore } from "components/hooks/useStore";
import AddToCartButton from "components/cart/AddToCartButton";
import DiscountSlider from "components/shop/DiscountSlider";
import { useApi } from "components/hooks/useApi";
import { useIsInIframe } from "components/hooks/useIsInIframe";

export function WidgetStoreProductsTable({
	tbl,
	noLinks = false,
	activeTab,
	setActiveTab,
	toggleItemsCopyIsOpenState,
	checkIfModalIsOpenState,
	closeModalClickAnyWhereAll,
	isWidget = false,
	isSingleStore,
	showDuplicates = false,
	storeId,
	tabletMode = false,
	isOrdersEnabled,
	defaultTab = "hours",
	isSubdomain = false,
	...props
}) {
	if (tabletMode) {
		isOrdersEnabled = false;
	}

	const cache = useContext(CacheContext);
	const { userCarts, addToCart } = useUserCarts();
	const { data: store } = useStore(storeId);
	const cart = userCarts.find(cart => cart.storeId === Number(store?.id));
	const api = useApi();
	const isInIframe = useIsInIframe();
	const [discountsList, setDiscountsList] = useState([]);

	useEffect(() => {
		const getStoreDiscounts = async storeId => {
			const res = await api.getFullStoreDiscounts(storeId);
			if (res.ok) {
				const discounts = res.data.items;
				setDiscountsList(discounts);
			}
		};
		if (store?.id) {
			getStoreDiscounts(store.id);
		}
	}, [store]);

	const filters = useMemo(() => {
		let filterList = [
			{
				property: "is_discount_exists",
				name: "מבצעים",
				placeholder: "מבצעים",
				type: "toggle",
				icon: <GiftIconOutline className="text-red-500 w-4 h-5" />,
				condition: "in",
				options: [{ value: "True", label: "במבצע" }],
				isSearchableFilter: false,
			},
		];
		return [
			...filterList,
			...getProductFilters(cache)
				.map(f => {
					// Check if the property is 'is_in_price_range '
					let modifiedProperty = f.property;
					if (f.property === "is_in_price_range") {
						modifiedProperty = "store_price";
					} else {
						modifiedProperty = `product__${f.property}`;
					}
					return {
						...f,
						property: modifiedProperty,
					};
				})
				.filter(
					filter =>
						filter.property !== "product__store_has_delivery" &&
						filter.property !== "product__store_products__store__city_id" &&
						filter.property !== "product__in_stock",
				),
		];
	}, [cache]);

	const is48HoursPast = !!store?.hours_past_48;
	const enableOrders = !!store?.enable_orders;

	const cartCol = {
		id: "add_to_cart",
		width: 100,
		Cell: props => (
			<div className="relative flex items-center justify-center ml-[1px]">
				<AddToCartButton
					storeId={props.row.original.store_id}
					product={props.row.original}
					enableOrders={enableOrders}
					type="icon"
					referral={
						isInIframe
							? "iframe in cannabiz store"
							: isSubdomain
							? "Jane subdomain store"
							: "Jane widget store"
					}
				/>
			</div>
		),
	};
	const showCatalogPriceOverride = true;
	const showCartButton = !tabletMode && store?.enable_orders;
	const columns = showCartButton
		? [
				...ProductsTableColumns(props, showCatalogPriceOverride, true, true),
				cartCol,
		  ]
		: ProductsTableColumns(props, showCatalogPriceOverride, true, true);

	const getRowHiddenContent = (row, setModalViewProduct = () => {}) => {
		return (
			<ProductsTableHiddenRow
				withCartButton={showCartButton && enableOrders}
				isWidget={isWidget}
				isSingleStore={isSingleStore}
				row={row}
				tabletMode={tabletMode}
				setModalViewProduct={setModalViewProduct}
				showSimilarProducts={true}
			/>
		);
	};

	const handleSortChange = newSortOption => {
		tbl.changeSortBy([newSortOption]);
	};

	const handleItemsWithDiscounts = fbd => {
		let val = ["True"];
		if (fbd) {
			val = ["False"];
		}
		tbl.addAdditionalFilters({
			property: "is_discount_exists",
			value: val,
			condition: "in",
			labels: ["מבצעים"],
		});

		let msg = "הצגה של מוצרים המכילים מבצעים בלבד";

		if (fbd) {
			msg = "כולל מוצרים שאינם במבצע";
		}

		toastMessage(msg, "info");
	};

	if (!store) {
		return;
	}

	return (
		<div className="flex flex-col gap-6">
			{!tabletMode && (
				<SingleStoreHeader
					store={store}
					isWidget={isWidget}
					defaultTab={defaultTab}
				/>
			)}
			{tabletMode && (
				<DiscountSlider
					discountsList={discountsList}
					store={store}
					isTabletMode={tabletMode}
				/>
			)}

			<ServerTable
				tbl={tbl}
				columns={columns}
				filters={filters}
				getRowHiddenContent={getRowHiddenContent}
				showDuplicates={showDuplicates}
				hideOnScroll={false}
				isQuickfilter={true}
				showExpandedRowArrows={false}
				isHomeWidget={isWidget}
				subContent={<QuickFilters cache={cache} tbl={tbl} isSingleStore />}
				prependStoreSortByContent={
					<StoreSortBy
						onChange={handleSortChange}
						value={tbl?.sortBy[0]}
						className={tabletMode && "h-10"}
						options={STORE_SORTBY_OPTIONS}
					/>
				}
				isWithDiscountsFilter={false}
				is48HoursPast={is48HoursPast}
				cart={cart}
				enableOrders={showCartButton}
				searchable
				toggleItemsCopyIsOpenState={toggleItemsCopyIsOpenState}
				checkIfModalIsOpenState={checkIfModalIsOpenState}
				isStoreProducts={true}
				isSingleStore={true}
				isOrdersEnabled={isOrdersEnabled ?? store?.enable_orders}
				tabletMode={tabletMode}
				{...props}
			/>
		</div>
	);
}

import React from "react";
import { ModalDialog } from "./ModalDialog";
import { useUserCarts } from "components/jotai/cartAtom";
import Cart from "components/cart/Cart";
import HelpRow from "components/layout/HelpRow";

function StoreCartModal({ cart, store }) {
	const { setWidgetCartModal, widgetCartModal } = useUserCarts();
	return (
		<ModalDialog
			isOpen={widgetCartModal}
			title={`הסל שלי - ${store?.display_name}`}
			onSubmit={() => setWidgetCartModal(false)}
			onClose={() => {
				setWidgetCartModal(false);
			}}
			showSubmitButton={false}
			cancelBtnText="סגירה"
			maxWidth={600}
			closeOnClickOutside={true}
		>
			<Cart cart={cart} isWidget={true} />
			<HelpRow />
		</ModalDialog>
	);
}

export default StoreCartModal;

import {
	BriefcaseIcon,
	ClockIcon,
	LocationMarkerIcon,
	TruckIcon,
} from "@heroicons/react/outline";
import { SingleStoreDelivery } from "components/shop/SingleStoreDelivery";
import React, { useState } from "react";
import { Tooltip } from "react-tippy";
import { getToHour } from "util/common";
import { supportedHealthFundsOptions } from "util/types";

const PICKUP = "pickup";
const DELIVERY = "delivery";

const StoreInformation = ({ store, cart, setDeliveryPrice = () => {} }) => {
	const [information, setInformation] = useState(PICKUP);

	return (
		<div className="flex flex-col gap-2 md:gap-4">
			{store?.has_delivery && (
				<div className="flex gap-2 justify-around">
					<button
						className={`rounded-full px-3 py-1 cursor-pointer w-full text-center ${
							information === PICKUP
								? "bg-jane-700 text-white"
								: "bg-jane-200 text-black"
						}`}
						onClick={() => {
							setDeliveryPrice(null);
							setInformation(PICKUP);
						}}
					>
						איסוף עצמי
					</button>
					<button
						className={`rounded-full px-3 py-1 cursor-pointer w-full text-center ${
							information === DELIVERY
								? "bg-jane-700 text-white"
								: "bg-jane-200 text-black"
						}`}
						onClick={() => {
							setInformation(DELIVERY);
						}}
						disabled={!store.has_delivery}
					>
						משלוח
					</button>
				</div>
			)}
			<div className="bg-jane-200 flex flex-col gap-2 p-4 rounded-md">
				{information === PICKUP ? (
					<div className="flex flex-col gap-0.5">
						<div className="flex gap-1 items-center">
							<div className="flex items-center">
								<span className="flex items-center gap-1">
									<ClockIcon className="w-4 h-4 mt-[1px]" />
									<span>
										{!!store.is_currently_open ? (
											<div className="text-green-500 ">פתוח</div>
										) : (
											<div className="text-red-500">סגור</div>
										)}
									</span>
									&nbsp;⋅&nbsp;
									<span>{getToHour(store)}</span>
								</span>
							</div>
						</div>
						<div className="flex gap-1 items-center">
							<LocationMarkerIcon className="h-4 w-4" />
							<span>
								{store.city.heb_name}, {store.street_name} {store.street_num}
							</span>
						</div>
						{store?.supported_health_funds?.length > 0 &&
							!store?.supported_health_funds.includes("none") && (
								<div className="flex items-center gap-1">
									<BriefcaseIcon className="h-4 w-4 text-jane-900" />
									<div className="flex items-center gap-1.5">
										{store.supported_health_funds.map((healthFund, index) => {
											const fund = supportedHealthFundsOptions.find(
												option => option.value === healthFund,
											);
											return (
												<span key={healthFund}>
													<Tooltip
														title={`מכבדים מרשמי ${fund.label}`}
														placement="top"
														arrow
													>
														<img
															src={fund.icon}
															alt={fund.label}
															className="h-4 md:h-3.5"
														/>
													</Tooltip>
												</span>
											);
										})}
									</div>
								</div>
							)}
					</div>
				) : (
					<SingleStoreDelivery
						store={store}
						cart={cart}
						singleStoreView={false}
						setDeliveryPrice={setDeliveryPrice}
					/>
				)}
			</div>
		</div>
	);
};

export default StoreInformation;

import FileField from "components/fields/FileField";
import React, { useContext, useState } from "react";
import { Button } from "components/form/Button";
import { userDocTypes } from "util/types";
import PrescriptionsSwiper from "./PrescriptionsSwiper";
import { FILE_NAMES } from "components/hooks/useDownload";
import { AuthContext } from "components/contexts/AuthContext";
import { Toggle } from "components/form/Toggle";
import { useApi } from "components/hooks/useApi";
import { toastMessage } from "util/common";

function PrescriptionCheckout({ checkoutMethods, nextStep: nextStepperStep }) {
	const { nextStep, prescriptionMethods, onPrescriptionsOcr } = checkoutMethods;
	const { claims } = useContext(AuthContext);
	const api = useApi();

	const [checked, setChecked] = useState(
		claims.is_prescription_notifications_enabled,
	);

	const {
		sortPrescriptionsByDate,
		clearPrescriptions,
		deletePrescription,
		addPrescription,
		prescriptionForm,
		prescriptionNotificationToggle,
	} = prescriptionMethods;

	const {
		register,
		setValue,
		control,
		getValues,
		watch,
		formState: { errors },
	} = prescriptionForm;

	const handlePrescriptionToggle = async checked => {
		setChecked(checked);
		prescriptionNotificationToggle(checked);
	};

	const handleNext = () => {
		nextStep(nextStepperStep);
	};
	const activePrescriptions =
		getValues("prescriptions").filter(item => item.relevant === 0) || [];

	return (
		<div className="flex flex-col gap-4 overflow-x-hidden">
			<FileField
				storageName="documents"
				documentType={userDocTypes.user_mirsham}
				previewUrl={watch("previewUrl")}
				name={FILE_NAMES.PRESCRIPTION}
				control={control}
				required
				className="w-full h-full"
				setOcrData={onPrescriptionsOcr}
				errors={errors}
				onNewFile={clearPrescriptions}
				setValue={setValue}
			/>
			{activePrescriptions?.length > 0 && (
				<>
					<h3 className="mx-auto text-center font-semibold">מרשם</h3>
					<PrescriptionsSwiper
						prescriptions={activePrescriptions}
						deletePrescription={deletePrescription}
						addPrescription={addPrescription}
						control={control}
						max={getValues("prescriptions")?.length}
						register={register}
						sortPrescriptionsByDate={sortPrescriptionsByDate}
						errors={errors?.prescriptions}
						viewOnly
					/>
					<div className="self-end">
						<Toggle
							checked={checked}
							onToggle={e => {
								handlePrescriptionToggle(e.target.checked);
							}}
							text="התראה 5 ימים לפני תפוגת מרשם"
						/>
					</div>
				</>
			)}
			<div className="flex flex-col md:flex-row justify-between gap-4 mt-4">
				<Button onClick={handleNext} className="hidden md:flex" primary>
					המשך לשלב הבא
				</Button>
			</div>
		</div>
	);
}

export default PrescriptionCheckout;

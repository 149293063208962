import FileField from "components/fields/FileField";
import React, { useContext, useState } from "react";
import MedicalConditionSelect from "./MedicalConditionSelect";
import {
	NOT_ALLOWED_MEDICAL_CONDITIONS,
	sortedAllowedMedicalConditions,
} from "util/medicalConditions";
import { Button } from "components/form/Button";
import { userDocTypes } from "util/types";
import { AuthContext } from "components/contexts/AuthContext";
import { FILE_NAMES } from "components/hooks/useDownload";
import { Toggle } from "components/form/Toggle";

function LicenseCheckout(checkoutMethods) {
	const { nextStep, licenseForm, onLicenseOcr, licenseMethods } =
		checkoutMethods;
	const { licenseNotificationToggle } = licenseMethods;
	const { claims } = useContext(AuthContext);

	const [checked, setChecked] = useState(
		claims.is_license_notifications_enabled,
	);

	const {
		register,
		setValue,
		control,
		watch,
		handleSubmit,
		formState: { errors },
	} = licenseForm;

	const onSubmit = params => {
		nextStep(true);
	};

	const showNotificationToggle =
		!watch("is_medical_condition") &&
		watch("license_expiry") &&
		watch("license_start");

	const handleLicenseToggle = async checked => {
		setChecked(checked);
		licenseNotificationToggle(checked);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
			<label className="flex gap-2 items-center" htmlFor="is_medical_condition">
				<input
					type="checkbox"
					id="is_medical_condition"
					{...register("is_medical_condition")}
				/>
				אני מטופל/ת במרשם בלבד (ללא רישיון)
			</label>
			{!watch("is_medical_condition") && (
				<div className="flex flex-col gap-4 ">
					<FileField
						storageName="documents"
						documentType={userDocTypes.user_license}
						previewUrl={watch("previewUrl")}
						name={FILE_NAMES.LICENSE}
						control={control}
						label="צילום רישיון"
						required
						className="w-full h-full"
						setOcrData={onLicenseOcr}
						errors={errors}
						setValue={setValue}
					/>
				</div>
			)}
			{showNotificationToggle && (
				<div className="self-end">
					<Toggle
						checked={checked}
						onToggle={e => {
							handleLicenseToggle(e.target.checked);
						}}
						text="התראה על תפוגת רישיון"
					/>
				</div>
			)}
			{(!claims?.medical_condition || claims?.medical_condition === "") && (
				<div className="bg-jane-200 flex flex-col gap-2 rounded-md p-4 mt-4">
					<p>
						נשמח לדעת מה ההתוויה שלך לטיפול, כך נוכל לסייע לך באיתור המוצרים
						המתאימים ביותר
					</p>
					<label>
						התוויה{" "}
						<span className="text-jane-400 font-semibold text-sm">
							(לא חובה)
						</span>
						<MedicalConditionSelect
							control={control}
							options={[
								...sortedAllowedMedicalConditions,
								...NOT_ALLOWED_MEDICAL_CONDITIONS,
							]}
						/>
					</label>
					<p className="text-sm text-jane-400">
						המידע הזה יישאר אנונימי תמיד וישמש רק לצורך סטטיסטיקה
					</p>
				</div>
			)}

			<div className="flex flex-col md:flex-row justify-between gap-4 mt-4">
				<Button onClick={nextStep} className="hidden md:flex" primary>
					המשך למרשם
				</Button>
			</div>
		</form>
	);
}

export default LicenseCheckout;

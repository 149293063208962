import { useState } from "react";
import { useApi } from "./useApi";

export const FILE_NAMES = {
	ID: "id_url",
	LICENSE: "license_url",
	PRESCRIPTION: "mirsham_url",
	SIGNATURE: "signature_url",
};

const useDownload = () => {
	const [loading, setLoading] = useState({});
	const api = useApi();

	const getFileUrls = async (fieldName, orderId) => {
		try {
			setLoading({ ...loading, [fieldName]: true });
			const res = await api.getFileUrls({
				field_name: fieldName,
				order_id: orderId,
			});
			if (res.ok) {
				return res.data;
			}
		} catch (error) {
			console.error("Error creating file token");
		} finally {
			setLoading({ ...loading, [fieldName]: false });
		}
	};

	const getPresignedUrl = async (fieldName, orderId) => {
		if (loading[fieldName]) return;

		setLoading({ ...loading, [fieldName]: true });
		try {
			const res = await getFileUrls(fieldName, orderId);
			return res.presigned_url;
		} catch (error) {
			console.log("שגיאה בהורדת הקובץ", error);
		} finally {
			setLoading({ ...loading, [fieldName]: false });
		}
	};

	return {
		getPresignedUrl,
		getFileUrls,
		loading,
		setLoading,
	};
};

export default useDownload;

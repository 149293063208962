import { Fragment, useCallback, useEffect, useState } from "react";

import { ModalDialog } from "components/modals/ModalDialog";
import { useUserCarts } from "components/jotai/cartAtom";
import useDiscounts from "components/hooks/useDiscounts";
import CartAddButton from "./CartAddButton";
import BreakLine from "components/ui/BreakLine";
import { Button } from "components/form/Button";
import { FaExternalLinkAlt } from "react-icons/fa";
import { GiftIcon, TruckIcon } from "@heroicons/react/outline";
import {
	formatPrice,
	getTodayDayOfWeek,
	is48HoursPassed,
	productAlt,
	logoAlt,
} from "util/common";
import Link from "next/link";
import { TrashIcon } from "@heroicons/react/solid";
import { useAtom } from "jotai";
import { checkoutCartAtom } from "components/jotai/checkoutCartAtom";
import { useRouter } from "next/router";
import InfoBox from "components/ui/InfoBox";
import DiscountBadge from "./DiscountBadge";
import CartProductPrice from "./CartProductPrice";
import { MdOutlineImage } from "react-icons/md";
import { debounce } from "lodash";
import StoreInformation from "./StoreInformation";
import ProductImage from "components/ui/ProductImage";

export default function Cart({ cart, isWidget = false }) {
	const router = useRouter();
	const { store, storeId, products, discounts } = cart;
	const todayNum = getTodayDayOfWeek(); //day of the week in numbers (sunday = 1, monday = 2, ...)
	const [checkoutCart, setCheckoutCart] = useAtom(checkoutCartAtom);
	const { fetchDiscounts } = useDiscounts(storeId);
	const {
		removeCart,
		removeFromCart,
		getTotalPrice,
		validateCart,
		setWidgetOrderModal,
		setWidgetCartModal,
		getOriginalPrice,
	} = useUserCarts();
	const [loading, setLoading] = useState(false);
	const [removeItemModal, setRemoveItemModal] = useState({
		isOpen: false,
		product: null,
	});
	const [removeCartModal, setRemoveCartModal] = useState(false);
	const [totalItems, setTotalItems] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
	const [isCartValidated, setIsCartValidated] = useState(false);
	const [deliveryPrice, setDeliveryPrice] = useState(null);

	const validate = async () => {
		const param = {};
		const cartItems = cart.products.map(product => ({
			product_id: product.product_id,
			batch_id: product.batch_id ?? null,
			store_price: product.store_price,
			product_count: product.count,
			store_quantity: Number(product.quantity),
		}));
		param[cart.storeId] = cartItems;
		if (!isCartValidated) {
			setLoading(true);
			await validateCart(param);
			setLoading(false);
		}
		setIsCartValidated(true);
	};

	useEffect(() => {
		validate();
	}, []);

	const debounceDiscountsFetch = useCallback(
		debounce(async () => {
			await fetchDiscounts();
			setLoading(false);
		}, 750),
		[fetchDiscounts, setLoading, cart],
	);

	useEffect(() => {
		setTotalPrice(getTotalPrice(storeId));
	}, [JSON.stringify(cart?.discounts), JSON.stringify(cart?.products)]);

	useEffect(() => {
		setTotalItems(
			cart.products.reduce((acc, product) => acc + product.count, 0),
		);
		if (!isCartValidated || loading) {
			return;
		} else {
			setLoading(true);
			debounceDiscountsFetch();
		}
	}, [JSON.stringify(cart.products), isCartValidated]);

	const handleCheckout = async () => {
		setLoading(true);
		await fetchDiscounts();
		setTotalPrice(getTotalPrice(storeId));
		let updatedCart = {
			...cart,
			totalPrice: totalPrice,
			totalItems: totalItems,
		};
		if (cart?.discounts?.length) {
			const originalPrice = cart.discounts[0].original_cart_price;
			const priceAfterDiscount =
				cart.discounts[cart.discounts.length - 1].total_new_price;
			const discountAmount = originalPrice - priceAfterDiscount;
			updatedCart = {
				...updatedCart,
				discountAmount,
			};
		}
		setCheckoutCart(updatedCart);
		if (isWidget) {
			setWidgetCartModal(false);
			setWidgetOrderModal(true);
		} else {
			router.push(`/checkout/${storeId}`);
		}
		setLoading(false);
	};

	return (
		<>
			<ModalDialog
				isOpen={removeItemModal.isOpen}
				title={<h1>עגלת קניות</h1>}
				onSubmit={() => {
					removeFromCart(storeId, removeItemModal.product);
					setRemoveItemModal({ isOpen: false, product: null });
				}}
				onClose={() => setRemoveItemModal({ isOpen: false, product: null })}
				showSubmitButton={true}
				submitBtnText="הסרה"
				cancelBtnText="סגירה"
				maxWidth={600}
				closeOnClickOutside={true}
			>
				האם להסיר פריט זה?
			</ModalDialog>
			<ModalDialog
				isOpen={removeCartModal}
				title="הסרת עגלת קניות"
				onSubmit={() => {
					removeCart(storeId);
					setRemoveCartModal(false);
				}}
				onClose={() => setRemoveCartModal(false)}
				showSubmitButton={true}
				submitBtnText="הסר"
				cancelBtnText="חזרה"
				maxWidth={600}
				closeOnClickOutside={true}
			>
				האם להסיר את כל הפריטים מבית המרקחת?
			</ModalDialog>

			<div
				className={`relative flex flex-col w-full max-w-3xl ${
					!isWidget && "md:flex-row md:gap-4"
				}`}
			>
				<div
					className={`bg-white h-fit w-full rounded-t-md  flex flex-col gap-4 p-4 pb-0 relative ${
						!isWidget && "md:pb-4 md:w-5/8 md:rounded-md"
					}`}
				>
					<TrashIcon
						className="w-6 h-6 absolute cursor-pointer -left-2 -top-2 transition-all text-jane-600 hover:text-red-700"
						onClick={e => {
							setRemoveCartModal(true);
						}}
					/>
					<div className="flex items-center gap-2 md:gap-4">
						{!!store?.logo_url ? (
							<img
								src={store.logo_url}
								alt={logoAlt(store)}
								className="h-10 w-10 rounded-full"
							/>
						) : (
							<MdOutlineImage className="text-jane-400 h-10 w-10" />
						)}

						<span className="text-lg font-semibold flex gap-2 items-center text-jane-700">
							{store.display_name}
							<Link href={`/store/${storeId}`}>
								<a>
									<FaExternalLinkAlt className="w-3 h-3 cursor-pointer" />
								</a>
							</Link>
						</span>
					</div>
					<BreakLine />
					{products.map((product, index, self) => {
						return (
							<Fragment key={index}>
								<div className="flex gap-2">
									<Link
										href={`/products/${product.id}`}
										passHref
										className="cursor-pointer"
									>
										<a target="_blank" rel="noreferrer noopener">
											<ProductImage product={product} className="w-20 h-20" />
										</a>
									</Link>
									<div className="flex flex-col gap-1">
										<Link href={`/products/${product.id}`} passHref>
											<a target="_blank" rel="noreferrer noopener">
												<span className="font-semibold leading-none md:text-lg cursor-pointer">
													{product.heb_name}
												</span>
											</a>
										</Link>

										{product?.batch && (
											<span className="text-jane-400 font-semibold text-xs md:text-sm">
												{product.batch.batch_id}
											</span>
										)}
										{product?.category && (
											<span className="text-sm md:text-base">
												{product.category}
											</span>
										)}
										<DiscountBadge
											productId={product.id}
											discounts={discounts}
										/>
									</div>
									<div className="flex flex-col justify-between mr-auto">
										<span>
											<CartAddButton
												cart={cart}
												product={product}
												setRemoveItemModal={setRemoveItemModal}
											/>
										</span>
										<span className="text-end">
											<CartProductPrice
												product={product}
												discounts={discounts}
											/>
										</span>
									</div>
								</div>
								{index !== self.length - 1 && <BreakLine />}
							</Fragment>
						);
					})}
				</div>
				<div
					className={`bg-white w-full  rounded-b-md  flex flex-col gap-4 p-4 h-fit ${
						!isWidget && "md:rounded-md md:w-3/8 md:sticky md:top-[72px]"
					}`}
				>
					<BreakLine className={`${!isWidget && "md:hidden"}`} />

					<StoreInformation
						store={store}
						cart={{
							...cart,
							totalPrice: totalPrice,
							totalItems: totalItems,
						}}
						setDeliveryPrice={setDeliveryPrice}
					/>

					<BreakLine />
					<div className="flex flex-col gap-1">
						<div className="flex justify-between w-full">
							<span className="font-semibold text-base flex items-center gap-1">
								סך הכל מוצרים:
							</span>
							<span className="font-semibold text-base">
								{totalItems * 10} גרם
							</span>
						</div>
						{(discounts?.length || deliveryPrice || deliveryPrice == 0) && (
							<>
								<div className="flex justify-between items-center font-semibold text-base  gap-1">
									<p>מחיר:</p>
									{loading ? (
										<div className="animate-pulse duration-75 bg-jane-200 h-6 w-10 rounded-md" />
									) : (
										<p>
											₪
											{!!discounts?.length
												? formatPrice(discounts[0].original_cart_price)
												: formatPrice(getTotalPrice(storeId))}
										</p>
									)}
								</div>
								<BreakLine />
							</>
						)}

						{loading ? (
							<span className="animate-pulse duration-75 bg-jane-200 h-6 w-full rounded-md" />
						) : (
							<>
								{(!!deliveryPrice || deliveryPrice == 0) && (
									<div className="flex justify-between items-center font-semibold gap-1">
										<p className="flex gap-1 items-center">
											<TruckIcon className="h-4 w-4" /> עלות משלוח:
										</p>
										{deliveryPrice == 0 ? (
											<p>חינם</p>
										) : (
											<p>₪{formatPrice(deliveryPrice)}</p>
										)}
									</div>
								)}
								{discounts?.map(discount => {
									return (
										<div
											key={discount.discount_id}
											className="flex justify-between gap-2 w-full"
										>
											<span className="font-semibold text-base gap-1">
												<GiftIcon className="w-4 h-4 ml-1 inline" />
												{discount.title}
											</span>
											<span className="font-semibold text-base text-red-500">
												₪{formatPrice(discount.total_discount)}
												{"-"}
											</span>
										</div>
									);
								})}
							</>
						)}
					</div>
					<BreakLine />
					{is48HoursPassed(new Date(store?.stock_updated_at)) && (
						<InfoBox text="בית המרקחת לא עדכן את המלאי" />
					)}
					<div className="flex justify-between gap-2 items-center">
						<Button
							primary
							disabled={
								is48HoursPassed(new Date(store?.stock_updated_at)) || loading
							}
							onClick={handleCheckout}
						>
							<span className="p-1 text-base whitespace-nowrap md:p-0">
								{loading ? "בודק מלאי" : "השלמת הזמנה"}
							</span>
						</Button>
						<span className="underline font-semibold text-lg">
							{loading ? (
								<span className="animate-pulse duration-75 bg-jane-200 block h-6 w-20 rounded-md" />
							) : (
								<>
									{" "}
									סה&apos;&apos;כ: ₪
									{formatPrice(getTotalPrice(storeId) + deliveryPrice)}
								</>
							)}
						</span>
					</div>
				</div>
			</div>
		</>
	);
}

import { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useApi } from "./useApi";
import { AuthContext } from "components/contexts/AuthContext";
import { productCategoryOptions } from "util/types";
import useOcr from "./useOcr";
import moment from "moment";
import { toastMessage } from "util/common";
import useLocalStorage from "./useLocalStorage";
import { productTypeOptionsForPrescriptions } from "util/types";
import { uniqueId } from "lodash";

const categoryRegexPattern = /\bT\s*(\d+)\s*(?:\/?\s*C\s*(\d+))?\b/;
const gramsRegexPattern = /סה"כ (\d+)/;

function convertToISODate(dateString) {
	return moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
}

const usePrescriptions = () => {
	const api = useApi();
	const { claims, authenticated } = useContext(AuthContext);
	const { getFromLocalStorage } = useLocalStorage(`j-user`);

	const [loading, setLoading] = useState(false);

	const [mainPrescription, setMainPrescription] = useState(null);

	const {
		updatedFields: updatedPrescriptionFields,
		setUpdatedFields,
		triggerUpdatedAnimation,
		updateField,
		testDate,
	} = useOcr();

	const prescriptionForm = useForm({
		defaultValues: {
			mirsham_url: "",
			previewUrl: "",
			prescriptions: [],
			prescriptionProducts: [],
		},
	});

	//	[{"number": "123456", "from_date": "30/03/2022", "to_date": "30/04/2022"},
	//	{"number": "234567", "from_date": "01/05/2022", "to_date": "30/05/2022"}, ...]
	const prescriptionsForm = useFieldArray({
		control: prescriptionForm.control,
		name: "prescriptions",
	});

	//	[{"grams": 20, "type": "flower", "category": "T22/C4"},{"grams": 30, "type": "oil", "category": "T18/C3"}, ...]
	const prescriprionProductForm = useFieldArray({
		control: prescriptionForm.control,
		name: "prescriptionProducts",
	});

	const gramsOptions = [];
	for (let i = 1; i <= 10; i++) {
		gramsOptions.push({ value: i * 10, label: `${i * 10} גרם` });
	}

	useEffect(() => {
		if (authenticated) {
			loadPrescriptions(); //this will update relevant form fields
		}
	}, [authenticated]);

	const loadPrescriptions = async () => {
		try {
			const res = await api.getUserPrescriptions();
			let mainPrescription = null;
			if (res.ok) {
				mainPrescription = res.data.data.find(
					prescription => prescription.main && !prescription.is_deleted,
				);
				if (!mainPrescription) {
					setMainPrescription(null);
				} else {
					if (!!mainPrescription?.mirsham_data?.length) {
						const refactoredPrescriptionProducts =
							mainPrescription?.mirsham_data?.map(prescription => {
								return {
									type: productTypeOptionsForPrescriptions.find(
										type => type.value === prescription.type,
									),
									category: productCategoryOptions.find(
										category => category.value === prescription.category,
									),
									grams: {
										label: `${prescription.grams} גרם`,
										value: prescription.grams,
									},
									randomId: Math.random(),
								};
							});

						mainPrescription.prescriptionProducts =
							refactoredPrescriptionProducts ?? [];
					}
					if (!!mainPrescription.mirsham_pages?.length) {
						const refactoredPrescriptions = sortPrescriptionsByDate(
							mainPrescription.mirsham_pages,
						);
						mainPrescription.prescriptions = refactoredPrescriptions ?? [];
					}
					setMainPrescription(mainPrescription);
				}
			}

			const storedPrescription = getFromLocalStorage("prescriptionForm");

			const mirshamUrl = !!storedPrescription?.mirsham_url
				? storedPrescription?.mirsham_url
				: mainPrescription?.mirsham_url || "";

			const mirshamPages = !!storedPrescription?.prescriptions
				? storedPrescription?.prescriptions
				: mainPrescription?.mirsham_pages || [];

			const mirshamPagesWithId = mirshamPages.map((page, index) => {
				return { ...page, randomId: uniqueId() };
			});

			prescriptionForm.setValue("mirsham_url", mirshamUrl);
			prescriptionForm.setValue("previewUrl", mainPrescription?.presigned_url);
			prescriptionForm.setValue("prescriptions", mirshamPagesWithId);
			prescriptionForm.setValue("id", mainPrescription.id);

			if (!!storedPrescription?.prescriptionProducts?.length) {
				const mirshamData = storedPrescription?.prescriptionProducts;
				prescriptionForm.setValue("prescriptionProducts", mirshamData);
			} else if (!!mainPrescription?.prescriptionProducts?.length) {
				prescriptionForm.setValue(
					"prescriptionProducts",
					mainPrescription.prescriptionProducts,
				);
			} else {
				prescriptionForm.setValue("prescriptionProducts", []);
			}
			sortPrescriptionsByDate();
		} catch (error) {
			console.error(error);
		}
	};

	const addPrescription = (prescription = null) => {
		let newPrescription = prescription;
		if (!newPrescription) {
			newPrescription = {
				number: "",
				from_date: "",
				to_date: "",
			};
		}
		prescriptionsForm.append({ ...newPrescription, randomId: uniqueId() });
		sortPrescriptionsByDate();
	};

	const apiDeletePrescription = async prescriptionId => {
		setLoading(true);
		const res = await api.softDeleteUserPrescription(prescriptionId);
		if (res.ok) {
			toastMessage("המרשם נמחק בהצלחה", "success");
		} else {
			toastMessage("שגיאה במחיקת מרשם", "error");
		}
		setLoading(false);
	};

	const deletePrescription = randomId => {
		const currentPrescriptions = prescriptionForm.getValues("prescriptions");
		const updatedPrescriptions = currentPrescriptions.filter(
			prescription => prescription.randomId !== randomId,
		);
		prescriptionForm.setValue("prescriptions", updatedPrescriptions);
	};

	const addPrescriptionProduct = (prescriptionProduct = null) => {
		let newPrescriptionProduct;
		if (
			!!newPrescriptionProduct?.type &&
			!!newPrescriptionProduct?.category &&
			!!newPrescriptionProduct?.grams
		) {
			newPrescriptionProduct = prescriptionProduct;
		} else {
			newPrescriptionProduct = {
				grams: "",
				type: "",
				category: "",
			};
		}
		prescriprionProductForm.append({
			...newPrescriptionProduct,
			randomId: uniqueId(),
		});
	};

	const deletePrescriptionProduct = randomId => {
		const currentPrescriptionProducts = prescriptionForm.getValues(
			"prescriptionProducts",
		);
		const updatedPrescriptionProducts = currentPrescriptionProducts.filter(
			(prescription, i) => prescription.randomId !== randomId,
		);
		prescriptionForm.setValue(
			"prescriptionProducts",
			updatedPrescriptionProducts,
		);
	};

	const insertPrescriptionOcrData = data => {
		const { prescriptions, prescriptionProducts } =
			constructPrescriptionOcrData(data);

		if (!prescriptions.length && !prescriptionProducts.length) {
			toastMessage("לא נמצאו נתונים במסמך", "error");
			return;
		}
		prescriptionForm.setValue("prescriptions", []);
		prescriptionForm.setValue("prescriptionProducts", []);

		if (prescriptionProducts?.length) {
			prescriptionProducts.forEach(prescription => {
				addPrescriptionProduct(prescription);
			});
		}

		if (prescriptions?.length) {
			prescriptions.forEach(p => {
				addPrescription(p);
			});
		}

		const currentPrescriptionProducts = prescriptionForm.getValues(
			"prescriptionProducts",
		);

		const combinedPrescriptions = [
			...currentPrescriptionProducts,
			...prescriptionProducts,
		];

		const uniquePrescriptions = combinedPrescriptions.filter(
			(prescription, index, self) =>
				index ===
				self.findIndex(
					t =>
						t?.type?.value === prescription?.type?.value &&
						t?.category?.value === prescription?.category?.value &&
						t?.grams?.value === prescription?.grams?.value,
				),
		);
		prescriptionForm.setValue("prescriptionProducts", uniquePrescriptions);
		sortPrescriptionsByDate();
	};

	const getRelevantPrescription = () => {
		const today = moment().format("YYYY-MM-DD"); // Get today's date in YYYY-MM-DD format
		const prescriptions = prescriptionForm.getValues("prescriptions");

		const relevantPrescriptions = prescriptions?.filter(prescription => {
			const todayDate = new Date(today);
			const fromDate = new Date(prescription.from_date);
			const toDate = new Date(prescription.to_date);

			// You can use prescription 5 days before it starts
			fromDate.setDate(fromDate.getDate() - 5);

			return fromDate <= todayDate && toDate >= todayDate;
		});
		if (!relevantPrescriptions?.length) return undefined;
		const relevantPrescription = {
			prescription: {
				...(relevantPrescriptions[0] ?? undefined),
			},
			prescriptionProducts: prescriptionForm.getValues("prescriptionProducts"),
		};
		return relevantPrescription;
	};

	const clearPrescriptions = () => {
		prescriptionForm.setValue("prescriptions", []);
		prescriptionForm.setValue("prescriptionProducts", []);
	};

	const getSortedPrescriptions = () => {
		const today = new Date();

		const relevant = [];
		const future = [];
		const expired = [];

		prescriptionForm.getValues("prescriptions")?.forEach(prescription => {
			const fromDate = new Date(prescription.from_date);
			const toDate = new Date(prescription.to_date);

			// You can use prescription 5 days before it starts
			fromDate.setDate(fromDate.getDate() - 5);
			if (fromDate <= today && toDate >= today) {
				relevant.push(prescription);
			} else if (fromDate > today) {
				future.push(prescription);
			} else {
				expired.push(prescription);
			}
		});

		return {
			relevant: [...relevant],
			future: [...future],
			expired: [...expired],
		};
	};

	const sortPrescriptionsByDate = prescriptions => {
		if (!prescriptions) {
			prescriptions = prescriptionForm.getValues("prescriptions");
		}
		const today = new Date();
		const sortedPrescriptions = prescriptions
			.sort((a, b) => new Date(a.to_date) - new Date(b.to_date))
			.map((prescription, index) => {
				const fromDate = new Date(prescription.from_date);
				const toDate = new Date(prescription.to_date);

				// You can use prescription 5 days before it starts
				fromDate.setDate(fromDate.getDate() - 5);
				let relevant = -1;
				if (today >= fromDate && today <= toDate) {
					relevant = 0;
				} else if (today < fromDate) {
					relevant = 1;
				}

				if (!prescription.randomId) {
					prescription.randomId = uniqueId();
				}
				return {
					...prescription,
					relevant,
					order: index + 1,
				};
			});

		prescriptionForm.setValue("prescriptions", sortedPrescriptions);
		return sortedPrescriptions;
	};

	const getPrescriptionsData = () => {
		const relevantPrescription = getRelevantPrescription();
		const data = {
			mirsham_url: prescriptionForm.getValues("mirsham_url"),
			mirsham_number: null,
			mirsham_expiry: null,
		};
		if (!!relevantPrescription) {
			data = {
				...data,
				mirsham_expiry: relevantPrescription?.prescription?.to_date ?? null,
				mirsham_number: relevantPrescription?.prescription?.number ?? null,
			};
		}
		console.log(data);
		return data;
	};

	const apiCreatePrescription = async data => {
		const param = data
			? {
					mirsham_data: data.prescriptionProducts.map(item => {
						return {
							category: item?.category?.value,
							grams: Number(item?.grams?.value),
							type: item?.type?.value,
						};
					}),
					mirsham_url: data.mirsham_url,
					mirsham_pages: data.prescriptions,
					main: true,
			  }
			: {
					mirsham_data: prescriptionForm
						.getValues("prescriptionProducts")
						.map(item => {
							return {
								category: item?.category?.value,
								grams: Number(item?.grams?.value),
								type: item?.type?.value,
							};
						}),
					mirsham_url: prescriptionForm.getValues("mirsham_url"),
					mirsham_pages: prescriptionForm.getValues("prescriptions"),
					main: true,
			  };
		try {
			const res = await api.createUserPrescription(param);
			if (!res.ok) {
				throw res.error.message;
			}
			toastMessage("מרשם נשמר בהצלחה", "success");
		} catch (error) {
			console.error(error);
			toastMessage("שגיאה בשמירת מרשם", "error");
		}
	};

	const apiEditPrescription = async data => {
		const param = data
			? {
					mirsham_data: data.prescriptionProducts.map(item => {
						return {
							category: item?.category?.value,
							grams: Number(item?.grams?.value),
							type: item?.type?.value,
						};
					}),
					mirsham_url: data.mirsham_url,
					mirsham_pages: data.prescriptions,
					main: true,
					id: data.id,
			  }
			: {
					mirsham_data: prescriptionForm
						.getValues("prescriptionProducts")
						.map(item => {
							return {
								category: item?.category?.value,
								grams: Number(item?.grams?.value),
								type: item?.type?.value,
							};
						}),
					mirsham_url: prescriptionForm.getValues("mirsham_url"),
					mirsham_pages: prescriptionForm.getValues("prescriptions"),
					main: true,
					id: prescriptionForm.getValues("id"),
			  };

		const prescriptionId = param.id;

		try {
			const res = await api.editUserPrescription(prescriptionId, param);
			if (!res.ok) {
				throw res.error.message;
			}
			toastMessage("מרשם נשמר בהצלחה", "success");
		} catch (error) {
			console.error(error);
			toastMessage("שגיאה בעדכון מרשם", "error");
		}
	};

	const handlePrescriptionSave = async (data = prescriptionForm.watch()) => {
		if (
			!!mainPrescription?.mirsham_url &&
			!!data?.mirsham_url &&
			mainPrescription.mirsham_url === data.mirsham_url
		) {
			apiEditPrescription(data);
		} else {
			apiCreatePrescription({ ...data, main: true });
		}
	};

	const prescriptionNotificationToggle = async val => {
		const params = { notifications_enabled: val };
		const res = await api.notifyExpiredPrescription(params);
		if (res.ok) {
			toastMessage(res.data.message, "success");
		} else {
			toastMessage("שגיאה בשמירת ההגדרה", "error");
		}
	};

	const constructPrescriptionOcrData = data => {
		const excludedData = data.data ?? [];
		const newPrescriptions = excludedData
			.map((item, index) => {
				if (!item?.user_mirsham || !Object.keys(item?.user_mirsham)?.length) {
					return null;
				}

				let number = item?.user_mirsham?.license_id?.replace(/\D/g, "") ?? null;

				let fromDate = item?.user_mirsham?.license_dates?.from ?? null;
				let toDate = item?.user_mirsham?.license_dates?.to ?? null;

				if (!!fromDate && testDate(fromDate)) {
					fromDate = convertToISODate(fromDate);
				} else {
					fromDate = null;
				}

				if (!!toDate && testDate(toDate)) {
					toDate = convertToISODate(toDate);
				} else {
					toDate = null;
				}

				return {
					number: number,
					from_date: fromDate,
					to_date: toDate,
					order: index + 1,
					randomId: uniqueId(),
				};
			})
			.filter(
				item => !!item && item?.from_date && item?.to_date && item?.number,
			);

		const newPrescriptionProducts = [];
		excludedData
			.map(item => {
				if (!item?.user_mirsham || !Object.keys(item?.user_mirsham)?.length) {
					return null;
				}
				const cannabisMirshams = item.user_mirsham?.cannabis || [];

				cannabisMirshams.forEach(mirsham => {
					const matchCategory = mirsham.match(categoryRegexPattern);
					const matchGrams = mirsham.match(gramsRegexPattern);
					let mirshamCategory = "";
					let mirshamType = "";
					let mirshamGrams = "";
					if (matchCategory) {
						const tValue = matchCategory[1];
						const cValue = matchCategory[2];
						mirshamCategory = "T" + tValue + "/C" + cValue;
					}
					if (matchGrams) {
						mirshamGrams = matchGrams[1];
					}
					if (mirsham.includes("תפרח") || mirsham.includes("גליל")) {
						mirshamType = "flower";
					} else if (mirsham.includes("מיצוי")) {
						mirshamType = "oil";
					}
					const mirsham_object = {
						type:
							productTypeOptionsForPrescriptions.find(
								type => type.value === mirshamType,
							) ?? "",
						category:
							productCategoryOptions.find(
								category => category.value === mirshamCategory,
							) ?? "",
						grams:
							gramsOptions.find(option => option.value === mirshamGrams) ?? "",
					};

					if (
						!mirsham_object.type ||
						!mirsham_object.category ||
						!mirsham_object.grams
					) {
						//* if any of the fields is missing, skip this item
						return null;
					}

					const isPrescriptionExists = newPrescriptionProducts.some(
						prescription =>
							prescription.type.value === mirshamType &&
							prescription.category.value === mirshamCategory &&
							prescription.grams.value === mirshamGrams,
					);
					if (!isPrescriptionExists) {
						newPrescriptionProducts.push(mirsham_object);
					}
				});
			})
			.filter(item => item !== null);

		return {
			prescriptions: newPrescriptions,
			prescriptionProducts: newPrescriptionProducts,
		};
	};

	return {
		loading,
		setLoading,
		mainPrescription,
		prescriptionForm,
		sortPrescriptionsByDate,
		getSortedPrescriptions,
		clearPrescriptions,
		apiCreatePrescription,
		getRelevantPrescription,
		insertPrescriptionOcrData,
		addPrescription,
		deletePrescription,
		addPrescriptionProduct,
		deletePrescriptionProduct,
		loadPrescriptions,
		handlePrescriptionSave,
		updatedPrescriptionFields,
		getPrescriptionsData,
		apiEditPrescription,
		apiDeletePrescription,
		constructPrescriptionOcrData,
		prescriptionNotificationToggle,
	};
};
export default usePrescriptions;

import { Disclosure, Menu } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { classNames, STORE_SORTBY_OPTIONS } from "util/common";

const StoreSortBy = ({ value, onChange, options, className }) => {
	const [selectedLabel, setSelectedLabel] = useState(
		STORE_SORTBY_OPTIONS[0].label,
	);

	const findLabelByValue = (searchValue, optionsArray) => {
		for (const option of optionsArray) {
			if (option.value === searchValue) {
				return option.label;
			}
			if (option.options) {
				const subOption = option.options.find(sub => sub.value === searchValue);
				if (subOption) {
					return subOption.label;
				}
			}
		}
		return STORE_SORTBY_OPTIONS[0].label;
	};

	useEffect(() => {
		const label = findLabelByValue(value, options);
		setSelectedLabel(label);
	}, [value, options]);

	const refs = useMemo(() => {
		return (
			options.map(() => {
				return createRef();
			}) ?? []
		);
	}, [options]);

	function handleClosingOthers(id) {
		const otherRefs = refs.filter(ref => {
			return ref.current?.getAttribute("data-id") !== id;
		});

		otherRefs.forEach(ref => {
			const isOpen = ref.current?.getAttribute("data-open") === "true";

			if (isOpen) {
				ref.current?.click();
			}
		});
	}

	return (
		<Menu as="div" className={classNames("relative ", className)}>
			{({ open }) => (
				<>
					<Menu.Button className="flex w-full h-full justify-center">
						<div
							tabIndex="0"
							className="w-[150px] flex justify-between h-full gap-1 items-center px-2 py-1 sm:px-2.5 sm:py-1.5 border rounded-md shadow-sm text-12px sm:text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-jane-500 text-gray-800 bg-white hover:bg-gray-50 border-gray-300"
						>
							<span>{selectedLabel}</span>
							<span>
								<ChevronUpIcon
									className={classNames(
										"w-3 h-3 transition-transform",
										open ? "rotate-0" : "-rotate-180",
									)}
								/>
							</span>
						</div>
					</Menu.Button>
					<Menu.Items>
						<div className="absolute bottom-auto shadow mt-1 right-0 bg-white w-40 rounded-md border">
							{options.map((opt, i) => {
								if (opt.options) {
									return (
										<Disclosure key={i}>
											{({ open: disclosureOpen }) => {
												return (
													<>
														<Disclosure.Button
															ref={refs[i]}
															data-id={opt.label}
															data-open={disclosureOpen}
															className="py-2 px-1 flex items-center justify-between w-full hover:bg-[#f9f9f9] transition-all cursor-pointer"
															onClick={() => handleClosingOthers(opt.label)}
														>
															<span className="text-sm">{opt.label}</span>
															<span>
																{disclosureOpen ? (
																	<ChevronUpIcon className="w-2 h-2" />
																) : (
																	<ChevronDownIcon className="w-2 h-2" />
																)}
															</span>
														</Disclosure.Button>
														<Disclosure.Panel className="text-gray-500">
															{opt.options.map((opt, j) => {
																return (
																	<Menu.Item key={j}>
																		{({ active }) => (
																			<div
																				className={classNames(
																					"py-2 px-1 text-sm hover:bg-[#f9f9f9] transition-all cursor-pointer",
																					active ? "bg-blue-500" : "text-black",
																				)}
																				onClick={() => onChange(opt.value)}
																			>
																				{opt.label}
																			</div>
																		)}
																	</Menu.Item>
																);
															})}
														</Disclosure.Panel>
													</>
												);
											}}
										</Disclosure>
									);
								} else {
									return (
										<Menu.Item key={i}>
											{({ active }) => (
												<div
													className={classNames(
														"py-2 px-1 text-sm hover:bg-[#f9f9f9] transition-all cursor-pointer",
														active ? "bg-blue-500" : "text-black",
													)}
													onClick={() => onChange(opt.value)}
												>
													{opt.label}
												</div>
											)}
										</Menu.Item>
									);
								}
							})}
						</div>
					</Menu.Items>
				</>
			)}
		</Menu>
	);
};

export default StoreSortBy;

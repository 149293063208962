import React from "react";
import DiscountCard from "./DiscountCard";
import { GiftIcon } from "@heroicons/react/outline";
import { useDragScroll } from "components/hooks/useDragScroll";

const DiscountSlider = ({
	discountsList,
	store,
	isTab,
	isTabletMode = false,
	isWidget,
}) => {
	const [scrollContanerRef] = useDragScroll();

	if (!store) return;
	return (
		<div
			className={`${
				isTabletMode ? "flex" : isTab ? "lg:hidden" : "lg:flex hidden"
			} w-full`}
		>
			{discountsList?.length > 0 && (
				<div className="bg-white rounded-md w-full">
					{!isTab && (
						<div
							className={`text-xl font-semibold ${
								isTabletMode ? "mb-2" : "ms-3 mt-4"
							}`}
						>
							מבצעים
							{!!discountsList && (
								<span className="text-jane-400 mr-1">
									({discountsList.length})
								</span>
							)}
						</div>
					)}
					<div
						ref={scrollContanerRef}
						className={`flex gap-6 overflow-x-auto py-5 overflow-y-hidden custom-scroll ${
							!isTab && !isTabletMode && "pb-7 px-7 pt-6"
						} ${isTabletMode && "pb-3"} `}
					>
						{discountsList.map(({ discount }) => {
							return (
								<div
									key={discount.id}
									className={`flex flex-col border-black/20 rounded-lg min-w-[250px] w-[250px] shadow-md h-[250px] max-h-[250px]`}
								>
									<div className="text-lg font-semibold bg-jane-700 rounded-t-lg text-white py-2 px-2 flex justify-between items-center">
										{discount.title}
										<GiftIcon className="w-5 h-5" />
									</div>
									<DiscountCard
										discount={{ ...discount, store: store }}
										isWidget={isWidget}
									/>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

export default DiscountSlider;

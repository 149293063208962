import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { useServerTable } from "components/hooks/useServerTable";
import Layout from "components/layout/layout";
import PageHeader from "components/layout/pageHeader";
import { WidgetStoreProductsTable } from "components/tables/WidgetStoreProductsTable";
import { useRouter } from "next/router";
import { checkHostnameAndRedirect } from "pages/store/[id]";
import React, { useContext, useEffect, useState } from "react";
import { getPharmacyPageViewGoogleEvent } from "util/analytics/handlers";
import { LAYOUT } from "util/common";
import { constructQuery, defaultOpts } from "util/ssrCommon";

export async function getServerSideProps(context) {
	const { id } = context.params;
	const { query } = context;
	const redirect = checkHostnameAndRedirect();

	try {
		const storeRes = await fetch(`${redirect}/api/stores/${id}/`, defaultOpts);
		const storeData = await storeRes?.json();

		if (storeData?.status !== "OK") {
			throw new Error("Store does not exist");
		}

		const expectedId = storeData.data.item.display_name_eng;
		if (expectedId !== id) {
			const queryString = constructQuery(query);

			const destination = `/widget/store/${expectedId}${
				queryString ? `?${queryString}` : ""
			}`;

			return {
				redirect: {
					destination,
					permanent: true,
				},
			};
		}

		return {
			props: {
				id: id,
				store: storeData.data.item,
			},
		};
	} catch (error) {
		return {
			redirect: {
				destination: "/404",
				permanent: false,
			},
		};
	}
}

export default function StoreProductsWidget({ store }) {
	const router = useRouter();
	const { collectAnalyticsGoogle } = useContext(AnalyticsContext);
	const isInIframe = useIsInIframe();
	const { id, title, nolinks } = router.query;
	const newId = id ? id.toLowerCase() : "";

	const params = {
		requestPath: `widget/products/store/${store?.display_name_eng || newId}/`,
		infiniteScrolling: true,
		pageSize: 25,
		layoutOptions: [LAYOUT.LIST, LAYOUT.GRID],
	};

	useEffect(() => {
		if (store) {
			const website = isInIframe
				? "iframe in cannabiz store"
				: "Jane widget store";
			const event = getPharmacyPageViewGoogleEvent(store, website);
			collectAnalyticsGoogle(event);
		}
	}, [store]);

	if (!store) return null;

	if (store) {
		const { is_active } = store;
		if (!is_active) {
			router.push("/products");
		}
	}

	if (!!store?.display_name_eng) {
		return (
			<StoreProductsByNewId
				id={store?.display_name_eng}
				title={title}
				nolinks={nolinks}
				params={params}
			/>
		);
	} else {
		return (
			<StoreProductsByOldId
				id={newId}
				title={title}
				nolinks={nolinks}
				params={params}
			/>
		);
	}
}

function StoreProductsByNewId({ id, title, nolinks, params }) {
	const [activeTab, setActiveTab] = useState("general");

	const tbl = useServerTable(params);

	return (
		<Layout>
			<div className="px-2 py-1">
				<PageHeader />
			</div>
			<WidgetStoreProductsTable
				tbl={tbl}
				title={title || "מוצרים"}
				noLinks={!!nolinks}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				isWidget={true}
				isSingleStore={true}
				showDuplicates={true}
				storeId={id}
			/>
		</Layout>
	);
}

function StoreProductsByOldId({ id, title, nolinks, params }) {
	const [activeTab, setActiveTab] = useState("general");

	const tbl = useServerTable(params);

	return (
		<Layout>
			<div className="px-2 py-1">
				<PageHeader />
			</div>
			<WidgetStoreProductsTable
				showDuplicates={true}
				tbl={tbl}
				title={title || "מוצרים"}
				noLinks={!!nolinks}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				isWidget={true}
				isSingleStore={true}
				storeId={id}
			/>
		</Layout>
	);
}

import { useApi } from "components/hooks/useApi";
import { useEffect, useState } from "react";

export function useStore(id) {
	const api = useApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);

	useEffect(() => {
		if (!id || !!loading) {
			return;
		}
		void load();
	}, [id]);

	const load = async () => {
		if (!!loading) {
			return;
		}
		setLoading(true);
		const res = await api.getStore(id);
		if (res.ok) {
			setData(res.data.item);
		} else {
			setError(res.error);
		}
		setLoading(false);
	};

	return {
		loading,
		error,
		setError,
		data,
		load,
	};
}
